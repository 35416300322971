import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Button from "../components/Core/Button";
import theme from "../utils/theme";
const Contact = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          isFluid: true,
          button: null, // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-md-11 bg-default-2">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9">Contact us</h2>
                  <p className="gr-text-8 mb-13">
                    If you have interest in developing or testing or technology,
                    please contact us. We are looking forward to further
                    discussing your use-case and licensing with you.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pb-5 pb-md-33 bg-default-2 ">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" className="mb-9">
                <Row>
                  <Col md="5" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 mr-7">
                        <i
                          className="icon icon-email-83"
                          style={{ color: theme.colors.primary }}
                        ></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-8 mb-3">Email</h3>
                        <p className="gr-text-10 mb-0">
                          info@reactivemarinetechnology.com
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md="5" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 mr-7">
                        <i
                          className="icon icon-pin-3-2"
                          style={{ color: theme.colors.primary }}
                        ></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-8 mb-3">Mail</h3>
                        <p className="gr-text-10 mb-0">
                          82 Blair Park Road, PO Box 325
                          <br />
                          Williston, VT USA 05495
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md="5" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 mr-7">
                        <i
                          className="icon icon-phone-2"
                          style={{ color: theme.colors.primary }}
                        ></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-8 mb-3">Call</h3>
                        <p className="gr-text-10 mb-0">+1-802-881-6323</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-5">
                  <form
                    name="contact2"
                    method="post"
                    action="https://getform.io/f/rmt-contact"
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact2" />
                    <Row>
                      <Col md="6">
                        <div className="form-group mb-7">
                          <label
                            htmlFor="name"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            First &amp; Last Name
                          </label>
                          <input
                            className="form-control gr-text-11 border"
                            type="text"
                            id="name"
                            name="name"
                            required
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group mb-7">
                          <label
                            htmlFor="email"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Email
                          </label>
                          <input
                            className="form-control gr-text-11 border"
                            type="text"
                            id="email"
                            name="email"
                            required
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group mb-7">
                          <label
                            htmlFor="phone"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Phone Number
                          </label>
                          <input
                            className="form-control gr-text-11 border"
                            type="text"
                            id="phone"
                            name="phone"
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group mb-7">
                          <label
                            htmlFor="company"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Company
                          </label>
                          <input
                            className="form-control gr-text-11 border"
                            type="text"
                            id="company"
                            name="company"
                            required
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group mb-7">
                          <label
                            htmlFor="message"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Message
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            className="form-control gr-text-11 border-gray-3 gr-textarea-height"
                            required
                          ></textarea>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="form-group button-block mt-3 ">
                          <Button
                            style={{ backgroundColor: theme.colors.primary }}
                            className="form-btn btn btn-primary w-100"
                            type="submit"
                          >
                            Send
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
